<template>
  <a-row type="flex" class="hotel-search">
    <a-col flex="auto" :style="{ padding: '20px 0px 20px 5px'}">
      <a-row>
        <a-col :span="7" class="item">
          <a-space direction="vertical" class="item-focusout">
            <a-space size="middle" v-if="!searchKeywordChecked">
              <span>{{ $t('destination') }}</span>
              <a-checkbox v-if="allowSearchKeyword" v-model:checked="searchKeywordChecked">{{ $t('search_keyword') }}</a-checkbox>
            </a-space>
            <a-space v-else>
              <span v-html="$t('only_search_in', { str: '<strong>' + city + '</strong>' })"></span> <CloseOutlined style="cursor: pointer;" @click="searchKeywordChecked=false" />
            </a-space>
            <a-auto-complete ref="dest_search" :bordered="false" :placeholder="$t('city') + ' / ' + $t('hotel')"
                             :allowClear="!destLoading" :listHeight="800"
                             style="width: 100%;" v-model:value="keywords" :options="destDataSource"
                             :dropdownMatchSelectWidth="false" dropdown-class-name="dest-search-dropdown" :autofocus="false"
                             @click="destClick" @change="destChange" @select="destSelect" @search="destSearch">
              <template #option="item">
                <template v-if="!isEmpty(item.value)">
                    <a-space>
                      <template v-if="item.ItemType === 'hotel'">
                        <svg-hotel />
                      </template>
                      <template v-else-if="item.ItemType === '70'">
                        <svg-airport />
                      </template>
                      <template v-else-if="item.ItemType === '100' || item.ItemType === '110'">
                        <svg-train />
                      </template>
                      <template v-else>
                        <svg-pin-city />
                      </template>
                      <div v-if="isZHCN">
                        <p class="title">{{ item.TitleCn }} {{ item.TitleEn }}</p>
                        <p class="geo">{{ getSubTitle(item.ItemType, getNameCn(item.SubTitleCn, item.SubTitleEn)) }}</p>
                      </div>
                      <div v-else>
                        <p class="title">{{ item.TitleEn }}</p>
                        <p class="geo">{{ getSubTitle(item.ItemType, item.SubTitleEn) }}</p>
                      </div>
                    </a-space>
                  </template>
                <template v-else>
                  {{ $t(item.country_code) }}
                </template>
              </template>
            </a-auto-complete>
            <LoadingOutlined style="float:right;margin-top: -30px;margin-right: 12px;color: #234BA1"
                             v-if="destLoading"/>
            <a-popover placement="bottomLeft" trigger="click" v-model:visible="hotDestVisible">
              <template #content>
                <a-tabs :style="{width:'850px'}">
                  <a-tab-pane :key="i" :tab="i" v-for="(cont,i) in TopCities">
                    <a-row :gutter="[8,8]">
                      <a-col :span="4" v-for="dest in cont" v-bind:key="dest.text" class="hot-city">
                        <a @click="selectHotDest(dest)">{{
                            dest.text
                          }}
                        </a>
                      </a-col>
                    </a-row>
                  </a-tab-pane>
                </a-tabs>
              </template>
              <span></span>
            </a-popover>
          </a-space>
        </a-col>
        <a-col :span="8" class="item">
          <a-space direction="vertical" class="item-focusout">
            <span><span>{{ $t('checkin_date') }}</span> <span style="float: right">{{
                $t('checkout_date')
              }}</span></span>
            <a-range-picker :bordered="false" :inputReadOnly="true" :allowClear="false" style="width: 100%;"
                            :value="checkInOut" :disabledDate="disabledDate" :format="checkInOutFormat" @calendarChange="calendarChange" @openChange="openDateChange" @change="dateChange">
              <template #separator>
                <span style="font-size: 12px;word-break:keep-all;white-space:nowrap;">{{
                    $tc('night_unit', nights, {'n': nights})
                  }}</span>
              </template>
            </a-range-picker>
          </a-space>
        </a-col>
        <a-col :span="5" class="item">
          <a-space direction="vertical" class="item-focusout">
            <span>
              <span>{{ $t('rooms') }}</span>
              <span style="float: right;margin-right: 15px;">{{ $t('per_room') }}</span>
            </span>
            <a-popover placement="bottom" trigger="click" :overlayStyle="{width:'235px'}">
              <template #content>
                <div class="paxroom-item">
                  <span>{{ $t('rooms') }}</span>
                  <span style="float: right">
                    <a-button type="link" size="small" @click="minusRooms">
                      <template #icon><MinusSquareOutlined/></template>
                    </a-button>
                    {{ this.searchState.PaxRoom.Count }}
                    <a-button type="link" size="small" @click="plusRooms">
                      <template #icon><PlusSquareOutlined/></template>
                    </a-button>
                  </span>
                </div>
                <div class="paxroom-item">
                  <strong>{{ $t('per_room') }}</strong>
                </div>
                <div class="paxroom-item">
                  <span>{{ $t('adults') }}</span>
                  <span style="float: right">
                    <a-button type="link" size="small" @click="minusAdults">
                      <template #icon><MinusSquareOutlined/></template>
                    </a-button>
                    {{ this.searchState.PaxRoom.Adults }}
                    <a-button type="link" size="small" @click="plusAdults">
                      <template #icon><PlusSquareOutlined/></template>
                    </a-button>
                  </span>
                </div>
                <div class="paxroom-item">
                  <span>{{ $t('children') }}</span>
                  <span style="float: right">
                    <a-button type="link" size="small" @click="minusChildren">
                      <template #icon><MinusSquareOutlined/></template>
                    </a-button>
                    {{ this.searchState.PaxRoom.Children.length }}
                    <a-button type="link" size="small" @click="plusChildren">
                      <template #icon><PlusSquareOutlined/></template>
                    </a-button>
                  </span>
                </div>
                <div class="paxroom-item" v-if="this.searchState.PaxRoom.Children.length > 0">
                  <div><strong>{{ $t('child_ages') }}</strong></div>
                  <a-space>
                    <div v-for="(age,i) in this.searchState.PaxRoom.Children" v-bind:key="i">
                      <a-select v-model:value="this.searchState.PaxRoom.Children[i]" :options="childAges" size="small"
                                :dropdownMatchSelectWidth="false">
                      </a-select>
                    </div>
                  </a-space>
                </div>
              </template>
              <div class="paxroom-item" style="cursor:pointer">
                <span>{{
                    $tc('room_unit', this.searchState.PaxRoom.Count, {'n': this.searchState.PaxRoom.Count})
                  }}</span>
                <span style="float: right">{{
                    $tc('adult_unit', this.searchState.PaxRoom.Adults, {'n': this.searchState.PaxRoom.Adults})
                  }} {{
                    $tc('child_unit', this.searchState.PaxRoom.Children.length, {'n': this.searchState.PaxRoom.Children.length})
                  }} <DownOutlined style="color:rgba(0, 0, 0, 0.25);font-size: 12px"/></span>
              </div>
            </a-popover>
          </a-space>
        </a-col>
        <a-col :span="4" class="item">
          <a-space direction="vertical" class="item-focusout">
            <span>{{ $t('nationality') }} / {{ $t('residency') }}</span>
            <a-select show-search :dropdownMatchSelectWidth="true" v-model:value="this.searchState.PaxRoom.Nationality"
                      :options="natiOptions" :bordered="false" :filter-option="filterOption" style="width:100%">
            </a-select>
          </a-space>
        </a-col>
      </a-row>
    </a-col>
    <a-col flex="130px">
      <a-button type="primary" html-type="submit" :style="{width:'100%', height: '100%'}" @click.stop="search">
        <template #icon>
          <SearchOutlined/>
        </template>
        {{ $t('search') }}
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
import { ref } from 'vue';
import dayjs from 'dayjs';
import {
  SearchOutlined,
  DownOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  LoadingOutlined,CloseOutlined
} from '@ant-design/icons-vue';
import {isEmpty,getNameCn} from "@/utils/util";
import {searchKeywords} from "@/services/hotel";
import SvgHotel from '@/assets/icon/hotel.svg';
import SvgPinCity from '@/assets/icon/pin-city.svg';
import SvgAirport from '@/assets/icon/airport-transfer-small.svg';
import SvgTrain from '@/assets/icon/train-new-solid.svg';
import { CommonNationalityCodes, AllCountries } from "@/data/country";
import { getTopCites } from "@/data/top-city";

export default {
  name: "HotelSearch",
  components: {
    SearchOutlined, DownOutlined, MinusSquareOutlined, PlusSquareOutlined, LoadingOutlined, SvgPinCity, SvgHotel, SvgAirport,SvgTrain, CloseOutlined
  },
  props: {
    propKeywords: String,
  },
  data() {
    const changeDates = ref([])
    const hackValue = ref()
    const checkInOut = ref([dayjs().add(1, 'day').endOf("days"), dayjs().add(2, 'day').endOf("days")])
    
    const disabledDate = current => {
      const today = current < dayjs().add(-1, 'day').endOf('day');
      if (!changeDates.value || changeDates.value.length === 0) {
        return today || false;
      }

      const tooLate = changeDates.value[0] && current.diff(changeDates.value[0], 'days') > 27;
      const tooEarly = changeDates.value[1] && changeDates.value[1].diff(current, 'days') > 27;
      return today || tooEarly || tooLate;
    };

    const openDateChange = open => {
      if (open) {
        changeDates.value = [];
        hackValue.value = []
      }else{
        hackValue.value = undefined
      }

      //console.log('openDateChange', open)
    };

    const calendarChange = dates => {
      if(!dates[1]) {
        dates[1] = dates[0].add(1, 'day').endOf("days")
      }
      if(!dates[0]) {
        dates[0] = dates[1].add(-1, 'day').endOf("days")
      }

      let days = dates[1].diff(dates[0], 'days')
      if(days > 27)
        dates[0] = dates[1].add(-1, 'day').endOf("days")
      else if(days === 0)
        dates[1] = dates[0].add(1, 'day').endOf("days")

      changeDates.value = dates
    };

    const dateChange = dates => {
      if(dates[1].diff(dates[0], 'days') === 0)
        dates[1] = dates[0].add(1, 'day').endOf("days")

      checkInOut.value = dates
    };

    const allowSearchKeyword = this.$route.name === 'hotel_list'

    return {
      openDate: true,
      checkInOutFormat: 'YYYY-MM-DD(dd)',
      checkInOut,
      hackValue,
      disabledDate,
      changeDates,
      calendarChange,
      openDateChange,
      dateChange,
      allowSearchKeyword,
      searchKeywordChecked:false,
      city: '',
      keywords: '',
      hotDestVisible: false,
      destLoading: false,
      timeout: 0,
      searchState: {
        HotelId: 0,
        CityCode: '',
        CheckIn: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        CheckOut: dayjs().add(2, 'day').format('YYYY-MM-DD'),
        PaxRoom: {
          Count: 1,
          Adults: 2,
          Children: [],
          Nationality: 'CN'
        },
      },
      childAges: [
        {value: 0, label: '<1'},
        {value: 1, label: '1'},
        {value: 2, label: '2'},
        {value: 3, label: '3'},
        {value: 4, label: '4'},
        {value: 5, label: '5'},
        {value: 6, label: '6'},
        {value: 7, label: '7'},
        {value: 8, label: '8'},
        {value: 9, label: '9'},
        {value: 10, label: '10'},
        {value: 11, label: '11'},
        {value: 12, label: '12'},
        {value: 13, label: '13'},
        {value: 14, label: '14'},
        {value: 15, label: '15'},
        {value: 16, label: '16'},
        {value: 17, label: '17'},
      ],
      destDataSource: [],
    }
  },
  beforeMount() {
    if(this.$route.params && this.$route.query) {
      if (this.$route.params.HotelId > 0 || !isEmpty(this.$route.params.CityCode)) {
        if (this.$route.params.HotelId > 0)
          this.searchState.HotelId = parseInt(this.$route.params.HotelId)
        if (!isEmpty(this.$route.params.CityCode))
          this.searchState.CityCode = this.$route.params.CityCode
        if (!isEmpty(this.$route.query.CheckIn)) {
          this.searchState.CheckIn = this.$route.query.CheckIn
        }
        if (!isEmpty(this.$route.query.CheckOut)) {
          this.searchState.CheckOut = this.$route.query.CheckOut
        }
        if (!isEmpty(this.$route.query.CheckIn) && !isEmpty(this.$route.query.CheckOut)) {
          this.checkInOut = [dayjs(this.searchState.CheckIn), dayjs(this.searchState.CheckOut)]
        }
        if (!isEmpty(this.$route.query.PaxRoom))
          this.searchState.PaxRoom = JSON.parse(this.$route.query.PaxRoom)
        if (!isEmpty(this.$route.query.Keyword)) {
          this.searchState.Keyword = this.$route.query.Keyword
          this.searchKeywordChecked = true
        }
      }
    }
  },
  mounted() {
    if (this.isZHCN)
      this.checkInOutFormat = 'YYYY-MM-DD(ddd)'
  },
  computed: {
    nights() {
      return this.checkInOut[1].diff(this.checkInOut[0], 'days')
    },
    isZHCN() {
      return this.$store.getters["setting/getLanguage"] === 'zh_CN'
    },
    TopCities(){
      return getTopCites(this.isZHCN)
    },
    natiOptions(){
      let isCn = this.isZHCN
      let optionGroups = [{label: this.$t('common_nationality'),options:[]},{label: this.$t('other_nationality'),options:[]}]
      AllCountries.forEach(country => {
        let label = country.code + " " + (isCn?country.name_cn:country.name_en)
        if(CommonNationalityCodes.find(code => code === country.code))
          optionGroups[0].options.push({ value:country.code, label:label})
        else
          optionGroups[1].options.push({ value:country.code, label:label})
      })

      return optionGroups
    }
  },
  watch: {
    checkInOut: function (arr) {
        this.searchState.CheckIn = arr[0].format('YYYY-MM-DD');
        this.searchState.CheckOut = arr[1].format('YYYY-MM-DD');
    },
    propKeywords: {
      immediate: true,
      handler(){
        if(isEmpty(this.keywords)) {
          this.keywords = this.propKeywords
        }
        if(this.allowSearchKeyword) {
          this.city = this.propKeywords
        }
      },
    },
    searchKeywordChecked: function(value) {
      if (value) {
        this.destDataSource = []
        this.keywords = this.searchState.Keyword
      }else{
        this.keywords = this.city
      }
    }
  },
  methods: {
    isEmpty,getNameCn,
    getSubTitle(itemType, subTitle) {
      if (itemType === 'hotel')
        return subTitle

      let arr = subTitle.split(',')
      arr.shift()
      return arr.join(',')
    },
    plusRooms() {
      if (this.searchState.PaxRoom.Count < 5)
        this.searchState.PaxRoom.Count++
    },
    minusRooms() {
      if (this.searchState.PaxRoom.Count > 1)
        this.searchState.PaxRoom.Count--
    },
    plusAdults() {
      if (this.searchState.PaxRoom.Adults < 8)
        this.searchState.PaxRoom.Adults++
    },
    minusAdults() {
      if (this.searchState.PaxRoom.Adults > 1)
        this.searchState.PaxRoom.Adults--
    },
    plusChildren() {
      if (this.searchState.PaxRoom.Children.length < 3)
        this.searchState.PaxRoom.Children.push(0)
    },
    minusChildren() {
      if (this.searchState.PaxRoom.Children.length > 0)
        this.searchState.PaxRoom.Children.pop()
    },
    filterOption(input, option){
      if(option.value === undefined)
        return false;

      return (option.value?option.value:'').toLowerCase().indexOf(input.toLowerCase()) >= 0 || (option.label?option.label:'').toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    selectHotDest(dest) {
      this.searchState.HotelId = 0
      this.searchState.CityCode = dest.value
      this.keywords = dest.text + ', ' + dest.country
      this.city = this.keywords
      this.hotDestVisible = false
    },
    destClick() {
      if(!this.searchKeywordChecked)
        this.hotDestVisible = isEmpty(this.keywords)
    },
    destChange() {
      if(!this.searchKeywordChecked) {
        this.hotDestVisible = isEmpty(this.keywords)
        if (this.hotDestVisible) {
          this.destDataSource = []
          this.searchState.HotelId = 0
          this.searchState.CityCode = ''
        }
      }
    },
    destSelect(item, option) {
      this.searchState.HotelId = 0
      this.searchState.CityCode = ''

      if(isEmpty(option.value))
        return

      if (option.ItemType === 'hotel') {
        this.keywords = this.isZHCN ? getNameCn(option.TitleCn, option.TitleEn) : option.TitleEn
        this.searchState.HotelId = parseInt(option.Id)
      } else {
        this.keywords = this.isZHCN ? getNameCn(option.SubTitleCn, option.SubTitleEn) : option.SubTitleEn
        this.city = this.keywords
        this.searchState.CityCode = option.Id
        if(option.Id !== option.AId)
          this.searchState.SubCityCode = option.AId
        else
          this.searchState.SubCityCode = undefined
      }
    },
    destSearch(value) {
      if(this.searchKeywordChecked)
        return

      if (this.timeout > 0)
        clearTimeout(this.timeout)

      if (value.length >= 2) {
        this.destLoading = true
        let _this = this
        this.timeout = setTimeout(function () {
          searchKeywords(value).then(res => {
            _this.destLoading = false
            const resData = res.data
            if (resData.Success) {
              resData.Data.forEach(item => item.value = item.ItemType + '_' + item.Id + '_' + item.AId)
              if (resData.Data.length == 0)
                _this.destDataSource = [{value:'', country_code:'no_data_keyword'}]
              else
                _this.destDataSource = resData.Data
            } else {
              _this.$message.error(this.$t('error.' + resData.Code))
            }
          }).catch(error => {
            console.log(error)
            _this.destLoading = false
          })
        }, 800)
      }else if(value.length == 1) {
        this.destDataSource = [{value: '', country_code: 'an_least_2_char'}]
      }
    },
    search() {
      if(isEmpty(this.searchState.CityCode) && this.searchState.HotelId <= 0) {
        this.$refs.dest_search.focus()
        this.hotDestVisible = true
        return
      }
      if(this.searchKeywordChecked){
        this.searchState.Keyword = this.keywords
      }else{
        this.searchState.Keyword = undefined
      }

      this.$emit('searchGo', this.searchState)
    }
  },
}
</script>
<style scoped>
.a-color {
  color: #333333;
}
.hot-city:hover {
  background-color: #f5f5f5;
}
.hot-city a{
  padding-left: 5px;
  display: block;
  width: 100%;
}
</style>
<style>
.paxroom-item {
  line-height: 30px;
}

.dest-search-dropdown .ant-select-item-option-content .title {
  color: #234BA1;
  line-height: 20px;
  font-weight: 400;
  margin:auto;
}

.dest-search-dropdown .ant-select-item-option-content .geo {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 20px;
  margin:auto;
}

.dest-search-dropdown svg {
  fill: #000000;
  fill-opacity: 0.8;
  height: 25px;
  width: 25px;
}

</style>