const TopCites = [
    {
        "id": 1,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3145,
        "title_cn": "上海",
        "title_en": "Shanghai",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 2,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 597,
        "title_cn": "北京",
        "title_en": "Beijing",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 3,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3354,
        "title_cn": "深圳",
        "title_en": "Shenzhen",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 4,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 6064259,
        "title_cn": "广州",
        "title_en": "Guangzhou",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 5,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3863,
        "title_cn": "厦门",
        "title_en": "Xiamen",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 6,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 6084436,
        "title_cn": "南京",
        "title_en": "Nanjing",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 7,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 930,
        "title_cn": "成都",
        "title_en": "Chengdu",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 8,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 6054541,
        "title_cn": "澳门",
        "title_en": "Macau",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 9,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 604,
        "title_cn": "曼谷",
        "title_en": "Bangkok",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 10,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 6046393,
        "title_cn": "普吉岛",
        "title_en": "Phuket",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 11,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 6035013,
        "title_cn": "苏梅岛",
        "title_en": "Koh Samui",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 12,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3168,
        "title_cn": "新加坡",
        "title_en": "Singapore",
        "sub_title_cn": "新加坡",
        "sub_title_en": "Singapore"
    },
    {
        "id": 13,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 602651,
        "title_cn": "巴厘岛",
        "title_en": "Bali",
        "sub_title_cn": "印度尼西亚",
        "sub_title_en": "Indonesia"
    },
    {
        "id": 14,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 184245,
        "title_cn": "香港",
        "title_en": "Hong Kong",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 15,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3518,
        "title_cn": "台北",
        "title_en": "Taipei",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 16,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3593,
        "title_cn": "东京",
        "title_en": "Tokyo",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 17,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 2697,
        "title_cn": "大阪",
        "title_en": "Osaka",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 18,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3124,
        "title_cn": "首尔",
        "title_en": "Seoul",
        "sub_title_cn": "韩国",
        "sub_title_en": "South Korea"
    },
    {
        "id": 19,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 2734,
        "title_cn": "巴黎",
        "title_en": "Paris",
        "sub_title_cn": "法国",
        "sub_title_en": "France"
    },
    {
        "id": 20,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 3341,
        "title_cn": "悉尼",
        "title_en": "Sydney",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 21,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 2261,
        "title_cn": "墨尔本",
        "title_en": "Melbourne",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 22,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 346,
        "title_cn": "奥克兰",
        "title_en": "Auckland",
        "sub_title_cn": "新西兰",
        "sub_title_en": "New Zealand"
    },
    {
        "id": 23,
        "group_cn": "热门",
        "group_en": "Top Cities",
        "city_id": 6053839,
        "title_cn": "迪拜",
        "title_en": "Dubai",
        "sub_title_cn": "阿拉伯联合酋长国",
        "sub_title_en": "United Arab Emirates"
    },
    {
        "id": 24,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 604,
        "title_cn": "曼谷",
        "title_en": "Bangkok",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 25,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6046393,
        "title_cn": "普吉",
        "title_en": "Phuket",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 26,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6056197,
        "title_cn": "清迈",
        "title_en": "Chiang Mai",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 27,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 602231,
        "title_cn": "甲米",
        "title_en": "Krabi",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 28,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6035013,
        "title_cn": "苏梅岛",
        "title_en": "Koh Samui",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 29,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6056227,
        "title_cn": "芭堤雅",
        "title_en": "Pattaya",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 30,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6049558,
        "title_cn": "华欣",
        "title_en": "Hua Hin",
        "sub_title_cn": "泰国",
        "sub_title_en": "Thailand"
    },
    {
        "id": 31,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 869,
        "title_cn": "科伦坡",
        "title_en": "Colombo",
        "sub_title_cn": "斯里兰卡",
        "sub_title_en": "Sri Lanka"
    },
    {
        "id": 32,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 1951,
        "title_cn": "吉隆坡",
        "title_en": "Kuala Lumpur",
        "sub_title_cn": "马来西亚",
        "sub_title_en": "Malaysia"
    },
    {
        "id": 33,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 11188,
        "title_cn": "沙巴",
        "title_en": "Sabah",
        "sub_title_cn": "马来西亚",
        "sub_title_en": "Malaysia"
    },
    {
        "id": 34,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 2766,
        "title_cn": "槟城",
        "title_en": "Penang",
        "sub_title_cn": "马来西亚",
        "sub_title_en": "Malaysia"
    },
    {
        "id": 35,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6126505,
        "title_cn": "兰卡威",
        "title_en": "Langkawi",
        "sub_title_cn": "马来西亚",
        "sub_title_en": "Malaysia"
    },
    {
        "id": 36,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 3168,
        "title_cn": "新加坡",
        "title_en": "Singapore",
        "sub_title_cn": "新加坡",
        "sub_title_en": "Singapore"
    },
    {
        "id": 37,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 2375,
        "title_cn": "马尼拉",
        "title_en": "Manila",
        "sub_title_cn": "菲律宾",
        "sub_title_en": "Philippines"
    },
    {
        "id": 38,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 800,
        "title_cn": "宿务",
        "title_en": "Cebu",
        "sub_title_cn": "菲律宾",
        "sub_title_en": "Philippines"
    },
    {
        "id": 39,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 601371,
        "title_cn": "长滩岛",
        "title_en": "Boracay Island",
        "sub_title_cn": "菲律宾",
        "sub_title_en": "Philippines"
    },
    {
        "id": 40,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 1704,
        "title_cn": "雅加达",
        "title_en": "Jakarta",
        "sub_title_cn": "印度尼西亚",
        "sub_title_en": "Indonesia"
    },
    {
        "id": 41,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 602651,
        "title_cn": "巴厘岛",
        "title_en": "Bali",
        "sub_title_cn": "印度尼西亚",
        "sub_title_en": "Indonesia"
    },
    {
        "id": 42,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 3140,
        "title_cn": "胡志明",
        "title_en": "Ho Chi Minh City",
        "sub_title_cn": "越南",
        "sub_title_en": "Vietnam"
    },
    {
        "id": 43,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6297250,
        "title_cn": "岘港",
        "title_en": "Da Nang",
        "sub_title_cn": "越南",
        "sub_title_en": "Vietnam"
    },
    {
        "id": 44,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6054458,
        "title_cn": "河内",
        "title_en": "Hanoi",
        "sub_title_cn": "越南",
        "sub_title_en": "Vietnam"
    },
    {
        "id": 45,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 6054439,
        "title_cn": "芽庄",
        "title_en": "Nha Trang",
        "sub_title_cn": "越南",
        "sub_title_en": "Vietnam"
    },
    {
        "id": 46,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 2975,
        "title_cn": "暹粒",
        "title_en": "Siem Reap",
        "sub_title_cn": "柬埔寨",
        "sub_title_en": "Cambodia"
    },
    {
        "id": 47,
        "group_cn": "东南亚",
        "group_en": "Southeast Asia",
        "city_id": 2837,
        "title_cn": "金边",
        "title_en": "Phnom Penh",
        "sub_title_cn": "柬埔寨",
        "sub_title_en": "Cambodia"
    },
    {
        "id": 48,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 184245,
        "title_cn": "香港",
        "title_en": "Hong Kong",
        "sub_title_cn": "中国香港",
        "sub_title_en": "Hong Kong (China)"
    },
    {
        "id": 49,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 6054541,
        "title_cn": "澳门",
        "title_en": "Macau",
        "sub_title_cn": "中国澳门",
        "sub_title_en": "Macau (China)"
    },
    {
        "id": 50,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 3518,
        "title_cn": "台北",
        "title_en": "Taipei",
        "sub_title_cn": "中国台湾",
        "sub_title_en": "Taiwan (China)"
    },
    {
        "id": 51,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 3593,
        "title_cn": "东京",
        "title_en": "Tokyo",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 52,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 2697,
        "title_cn": "大阪",
        "title_en": "Osaka",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 53,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 10323,
        "title_cn": "京都",
        "title_en": "Kyoto",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 54,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 10805,
        "title_cn": "冲绳本岛",
        "title_en": "Okinawa Main island",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 55,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 3250,
        "title_cn": "札幌",
        "title_en": "Sapporo",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 56,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 6336957,
        "title_cn": "福冈",
        "title_en": "Fukuoka",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 57,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 2554,
        "title_cn": "名古屋",
        "title_en": "Nagoya",
        "sub_title_cn": "日本",
        "sub_title_en": "Japan"
    },
    {
        "id": 58,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 3124,
        "title_cn": "首尔",
        "title_en": "Seoul",
        "sub_title_cn": "韩国",
        "sub_title_en": "South Korea"
    },
    {
        "id": 59,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": "553248635926569719",
        "title_cn": "济州",
        "title_en": "Jeju",
        "sub_title_cn": "韩国",
        "sub_title_en": "South Korea"
    },
    {
        "id": 60,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 602043,
        "title_cn": "釜山",
        "title_en": "Busan",
        "sub_title_cn": "韩国",
        "sub_title_en": "South Korea"
    },
    {
        "id": 61,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 597,
        "title_cn": "北京",
        "title_en": "Beijing",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 62,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 3145,
        "title_cn": "上海",
        "title_en": "Shanghai",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 63,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 6064259,
        "title_cn": "广州",
        "title_en": "Guangzhou",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 64,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 3354,
        "title_cn": "深圳",
        "title_en": "Shenzhen",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 65,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 3863,
        "title_cn": "厦门",
        "title_en": "Xiamen",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 66,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 6084436,
        "title_cn": "南京",
        "title_en": "Nanjing",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 67,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 930,
        "title_cn": "成都",
        "title_en": "Chengdu",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 68,
        "group_cn": "亚洲",
        "group_en": "Asia",
        "city_id": 6084457,
        "title_cn": "杭州",
        "title_en": "Hangzhou",
        "sub_title_cn": "中国",
        "sub_title_en": "China"
    },
    {
        "id": 69,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2011,
        "title_cn": "洛杉矶",
        "title_en": "Los Angeles",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 70,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 3132,
        "title_cn": "旧金山",
        "title_en": "San Francisco",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 71,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 3121,
        "title_cn": "西雅图",
        "title_en": "Seattle",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 72,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 3000459235,
        "title_cn": "圣迭戈",
        "title_en": "San Diego",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 73,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2008,
        "title_cn": "拉斯维加斯",
        "title_en": "Las Vegas",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 74,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 6085309,
        "title_cn": "休斯敦",
        "title_en": "Houston",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 75,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 660,
        "title_cn": "波士顿",
        "title_en": "Boston",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 76,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2621,
        "title_cn": "纽约",
        "title_en": "New York",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 77,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 829,
        "title_cn": "芝加哥",
        "title_en": "Chicago",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 78,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 3763,
        "title_cn": "华盛顿",
        "title_en": "Washington",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 79,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2693,
        "title_cn": "奥兰多",
        "title_en": "Orlando",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 80,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2297,
        "title_cn": "迈阿密",
        "title_en": "Miami",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 81,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 213,
        "title_cn": "夏威夷",
        "title_en": "Hawaii",
        "sub_title_cn": "美国",
        "sub_title_en": "United States"
    },
    {
        "id": 82,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 4106,
        "title_cn": "温哥华",
        "title_en": "Vancouver",
        "sub_title_cn": "加拿大",
        "sub_title_en": "Canada"
    },
    {
        "id": 83,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 4089,
        "title_cn": "多伦多",
        "title_en": "Toronto",
        "sub_title_cn": "加拿大",
        "sub_title_en": "Canada"
    },
    {
        "id": 84,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 4005,
        "title_cn": "蒙特利尔",
        "title_en": "Montreal",
        "sub_title_cn": "加拿大",
        "sub_title_en": "Canada"
    },
    {
        "id": 85,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2265,
        "title_cn": "墨西哥城",
        "title_en": "Mexico City",
        "sub_title_cn": "墨西哥",
        "sub_title_en": "Mexico"
    },
    {
        "id": 86,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 179995,
        "title_cn": "坎昆",
        "title_en": "Cancun",
        "sub_title_cn": "墨西哥",
        "sub_title_en": "Mexico"
    },
    {
        "id": 87,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2897,
        "title_cn": "巴拿马",
        "title_en": "Panama City",
        "sub_title_cn": "巴拿马",
        "sub_title_en": "Panama"
    },
    {
        "id": 88,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 3095,
        "title_cn": "圣地亚哥",
        "title_en": "Santiago",
        "sub_title_cn": "智利",
        "sub_title_en": "Chile"
    },
    {
        "id": 89,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 3074,
        "title_cn": "圣保罗",
        "title_en": "Sao Paulo",
        "sub_title_cn": "巴西",
        "sub_title_en": "Brazil"
    },
    {
        "id": 90,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2998,
        "title_cn": "里约热内卢",
        "title_en": "Rio de Janeiro",
        "sub_title_cn": "巴西",
        "sub_title_en": "Brazil"
    },
    {
        "id": 91,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 2077,
        "title_cn": "利马",
        "title_en": "Lima",
        "sub_title_cn": "秘鲁",
        "sub_title_en": "Peru"
    },
    {
        "id": 92,
        "group_cn": "美洲",
        "group_en": "America",
        "city_id": 716,
        "title_cn": "布宜诺斯艾利斯",
        "title_en": "Buenos Aires",
        "sub_title_cn": "阿根廷",
        "sub_title_en": "Argentina"
    },
    {
        "id": 93,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2114,
        "title_cn": "伦敦",
        "title_en": "London",
        "sub_title_cn": "英国",
        "sub_title_en": "United Kingdom"
    },
    {
        "id": 94,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2205,
        "title_cn": "曼彻斯特",
        "title_en": "Manchester",
        "sub_title_cn": "英国",
        "sub_title_en": "United Kingdom"
    },
    {
        "id": 95,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 1246,
        "title_cn": "法兰克福",
        "title_en": "Frankfurt",
        "sub_title_cn": "德国",
        "sub_title_en": "Germany"
    },
    {
        "id": 96,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2452,
        "title_cn": "慕尼黑",
        "title_en": "Munich",
        "sub_title_cn": "德国",
        "sub_title_en": "Germany"
    },
    {
        "id": 97,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 536,
        "title_cn": "柏林",
        "title_en": "Berlin",
        "sub_title_cn": "德国",
        "sub_title_en": "Germany"
    },
    {
        "id": 98,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 378,
        "title_cn": "阿姆斯特丹",
        "title_en": "Amsterdam",
        "sub_title_cn": "荷兰",
        "sub_title_en": "Netherlands"
    },
    {
        "id": 99,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2734,
        "title_cn": "巴黎",
        "title_en": "Paris",
        "sub_title_cn": "法国",
        "sub_title_en": "France"
    },
    {
        "id": 100,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2526,
        "title_cn": "尼斯",
        "title_en": "Nice",
        "sub_title_cn": "法国",
        "sub_title_en": "France"
    },
    {
        "id": 101,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 3023,
        "title_cn": "罗马",
        "title_en": "Rome",
        "sub_title_cn": "意大利",
        "sub_title_en": "Italy"
    },
    {
        "id": 102,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2302,
        "title_cn": "米兰",
        "title_en": "Milan",
        "sub_title_cn": "意大利",
        "sub_title_en": "Italy"
    },
    {
        "id": 103,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 3687,
        "title_cn": "威尼斯",
        "title_en": "Venice",
        "sub_title_cn": "意大利",
        "sub_title_en": "Italy"
    },
    {
        "id": 104,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 4191,
        "title_cn": "苏黎世",
        "title_en": "Zurich",
        "sub_title_cn": "瑞士",
        "sub_title_en": "Switzerland"
    },
    {
        "id": 105,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 438,
        "title_cn": "雅典",
        "title_en": "Athens",
        "sub_title_cn": "希腊",
        "sub_title_en": "Greece"
    },
    {
        "id": 106,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 177970,
        "title_cn": "圣托里尼岛",
        "title_en": "Santorini",
        "sub_title_cn": "希腊",
        "sub_title_en": "Greece"
    },
    {
        "id": 107,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2198,
        "title_cn": "马德里",
        "title_en": "Madrid",
        "sub_title_cn": "西班牙",
        "sub_title_en": "Spain"
    },
    {
        "id": 108,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 513,
        "title_cn": "巴塞罗那",
        "title_en": "Barcelona",
        "sub_title_cn": "西班牙",
        "sub_title_en": "Spain"
    },
    {
        "id": 109,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2395,
        "title_cn": "莫斯科",
        "title_en": "Moscow",
        "sub_title_cn": "俄罗斯",
        "sub_title_en": "Russia"
    },
    {
        "id": 110,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": "553248635975749278",
        "title_cn": "圣彼得堡",
        "title_en": "Saint Petersburg",
        "sub_title_cn": "俄罗斯",
        "sub_title_en": "Russia"
    },
    {
        "id": 111,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 3704,
        "title_cn": "维也纳",
        "title_en": "Vienna",
        "sub_title_cn": "奥地利",
        "sub_title_en": "Austria"
    },
    {
        "id": 112,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 1639,
        "title_cn": "伊斯坦布尔",
        "title_en": "Istanbul",
        "sub_title_cn": "土耳其",
        "sub_title_en": "Turkey"
    },
    {
        "id": 113,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 2872,
        "title_cn": "布拉格",
        "title_en": "Prague",
        "sub_title_cn": "捷克",
        "sub_title_en": "Czech Republic"
    },
    {
        "id": 114,
        "group_cn": "欧洲",
        "group_en": "Europe",
        "city_id": 906,
        "title_cn": "哥本哈根",
        "title_en": "Copenhagen",
        "sub_title_cn": "丹麦",
        "sub_title_en": "Denmark"
    },
    {
        "id": 115,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 3341,
        "title_cn": "悉尼",
        "title_en": "Sydney",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 116,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 2261,
        "title_cn": "墨尔本",
        "title_en": "Melbourne",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 117,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 641,
        "title_cn": "布里斯班",
        "title_en": "Brisbane",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 118,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 292,
        "title_cn": "阿德莱德",
        "title_en": "Adelaide",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 119,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": "553248635404168639",
        "title_cn": "凯恩斯",
        "title_en": "Cairns",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 120,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 180064,
        "title_cn": "黄金海岸",
        "title_en": "Gold Coast",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 121,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 786,
        "title_cn": "堪培拉",
        "title_en": "Canberra",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 122,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 2767,
        "title_cn": "珀斯",
        "title_en": "Perth",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 123,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 6052485,
        "title_cn": "霍巴特",
        "title_en": "Hobart",
        "sub_title_cn": "澳大利亚",
        "sub_title_en": "Australia"
    },
    {
        "id": 124,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 346,
        "title_cn": "奥克兰",
        "title_en": "Auckland",
        "sub_title_cn": "新西兰",
        "sub_title_en": "New Zealand"
    },
    {
        "id": 125,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 828,
        "title_cn": "基督城",
        "title_en": "Christchurch",
        "sub_title_cn": "新西兰",
        "sub_title_en": "New Zealand"
    },
    {
        "id": 126,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 3798,
        "title_cn": "惠灵顿",
        "title_en": "Wellington",
        "sub_title_cn": "新西兰",
        "sub_title_en": "New Zealand"
    },
    {
        "id": 127,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 180018,
        "title_cn": "皇后镇",
        "title_en": "Queenstown",
        "sub_title_cn": "新西兰",
        "sub_title_en": "New Zealand"
    },
    {
        "id": 128,
        "group_cn": "大洋洲",
        "group_en": "Oceania",
        "city_id": 6048125,
        "title_cn": "罗托鲁瓦",
        "title_en": "Rotorua",
        "sub_title_cn": "新西兰",
        "sub_title_en": "New Zealand"
    },
    {
        "id": 129,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 6053839,
        "title_cn": "迪拜",
        "title_en": "Dubai",
        "sub_title_cn": "阿拉伯联合酋长国",
        "sub_title_en": "United Arab Emirates"
    },
    {
        "id": 130,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 453,
        "title_cn": "阿布扎比",
        "title_en": "Abu Dhabi",
        "sub_title_cn": "阿拉伯联合酋长国",
        "sub_title_en": "United Arab Emirates"
    },
    {
        "id": 131,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 3152,
        "title_cn": "沙迦",
        "title_en": "Sharjah",
        "sub_title_cn": "阿拉伯联合酋长国",
        "sub_title_en": "United Arab Emirates"
    },
    {
        "id": 132,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 6269113,
        "title_cn": "多哈",
        "title_en": "Doha",
        "sub_title_cn": "卡塔尔",
        "sub_title_en": "Qatar"
    },
    {
        "id": 133,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 3477,
        "title_cn": "特拉维夫",
        "title_en": "Tel Aviv",
        "sub_title_cn": "以色列",
        "sub_title_en": "Israel"
    },
    {
        "id": 134,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 6269089,
        "title_cn": "安曼",
        "title_en": "Amman",
        "sub_title_cn": "约旦",
        "sub_title_en": "Jordan"
    },
    {
        "id": 135,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 1809,
        "title_cn": "卡拉奇",
        "title_en": "Karachi",
        "sub_title_cn": "巴基斯坦",
        "sub_title_en": "Pakistan"
    },
    {
        "id": 136,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 767,
        "title_cn": "开罗",
        "title_en": "Cairo",
        "sub_title_cn": "埃及",
        "sub_title_en": "Egypt"
    },
    {
        "id": 137,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 359,
        "title_cn": "阿尔及尔",
        "title_en": "Algiers",
        "sub_title_cn": "阿尔及利亚",
        "sub_title_en": "Algeria"
    },
    {
        "id": 138,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 2523,
        "title_cn": "内罗毕",
        "title_en": "Nairobi",
        "sub_title_cn": "肯尼亚",
        "sub_title_en": "Kenya"
    },
    {
        "id": 139,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 2216,
        "title_cn": "蒙巴萨",
        "title_en": "Mombasa",
        "sub_title_cn": "肯尼亚",
        "sub_title_en": "Kenya"
    },
    {
        "id": 140,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 3699,
        "title_cn": "维多利亚瀑布",
        "title_en": "Victoria Falls",
        "sub_title_cn": "津巴布韦",
        "sub_title_en": "Zimbabwe"
    },
    {
        "id": 141,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 178272,
        "title_cn": "约翰内斯堡",
        "title_en": "Johannesburg",
        "sub_title_cn": "南非",
        "sub_title_en": "South Africa"
    },
    {
        "id": 142,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 910,
        "title_cn": "开普敦",
        "title_en": "Cape Town",
        "sub_title_cn": "南非",
        "sub_title_en": "South Africa"
    },
    {
        "id": 143,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 115,
        "title_cn": "毛里求斯",
        "title_en": "Mauritius",
        "sub_title_cn": "毛里求斯",
        "sub_title_en": "Mauritius"
    },
    {
        "id": 144,
        "group_cn": "中东/非洲",
        "group_en": "Mid East/Africa",
        "city_id": 159,
        "title_cn": "塞舌尔",
        "title_en": "Seychelles",
        "sub_title_cn": "塞舌尔",
        "sub_title_en": "Seychelles"
    }
]

export function getTopCites(isZHCN) {
    let groups = {}
    TopCites.forEach(item => {
        if(isZHCN) {
            groups[item.group_cn] = groups[item.group_cn] || []
            groups[item.group_cn].push({value: item.city_id, text: item.title_cn, country: item.sub_title_cn})
        }else{
            groups[item.group_en] = groups[item.group_en] || []
            groups[item.group_en].push({value: item.city_id, text: item.title_en, country: item.sub_title_en})
        }
    })
    return groups
}